import InApp from 'detect-inapp';

const inapp = new InApp(
  navigator.userAgent || navigator.vendor || window.opera,
);

function detectInApp(domElementId, ua) {
  //dont do any action if its desktop version or not inApp Browser
  if (ua.isDesktop() || !ua.isInApp()) return;

  // let deviceMessage = ua.device;

  let input = document.createElement('input'),
    button = document.createElement('button'),
    text = window.location.href;
  //assign the url to the input text
  input.value = text;

  button.className = 'myButton';
  input.className = 'myInput';

  //intial button value !
  button.innerText = 'COPY URL';

  // copy text and change the button text to copied !
  button.addEventListener('click', function() {
    input.select();
    document.execCommand('copy');
    this.innerText = 'COPIED  !!!';
  });
  const source =
    ua.os === 'android'
      ? './src/facebook-android-inapp-browser.jpg'
      : './src/facebook-iOS-inapp-browser.jpg';

  let myDomElement = document.getElementById(domElementId);
  myDomElement.innerHTML = `
  <div class="warning-msg">
  you are using ${ua.browser}'s browser for ${ua.os} which unfortunately doesn't support some of our features , 
 to benifit from our full features open the website in your favorite browser , to achive that easily you can follow our tips bellow!
  </div>
  <h3>Option 1°:</h3>
  use the button Open in ...<br/><br/>
  <img class="myImage" alt="explaining how to open in other browser" src="${source}"/>
  <h3>Option 2°:</h3>
  simply copy the url and paste it in your favourite browser.<br />
  <br/>
  `;

  myDomElement.append(input);
  myDomElement.append(button);
}

detectInApp('app', inapp);
